export const Integration = (props) => {
  return (
    <div id="integration">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="about-text">
              <h2>Tracciamento da fornitore a cliente</h2>
              <h3>Integrazione con Voucher 51</h3>
              <p>{props.data ? props.data.paragraph1 : "loading..."}</p>
              <img src="img/integration1.png" className="img-responsive integration1-img" alt="" />{" "}
            </div>
          </div>
          <div className="col-xs-12 col-md-4 about-caption">
            {" "}
            <img src="img/integration2.png" className="img-responsive integration2-img" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  );
};
