export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 about-caption" style={{maxWidth: '400px'}}>
            {" "}
            <img src="img/about-1.png" className="img-responsive" alt="" />{" "}
            <div>
              <a href={props.data ? props.data.appstore : '/'} target='_blank'>
                <img src="img/app-store.png" className='store-icon store-ios' alt="Get On Time on the App Store" style={{maxWidth: '150px'}}/>
              </a>
              <a href={props.data ? props.data.playstore : '/'} target='_blank'>
                <img src="img/play-store.png" className='store-icon store-android' alt="Get On Time on the Play Store" style={{maxWidth: '150px'}}/>
              </a>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Chi Siamo</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
              <h3>Perché On Time?</h3>
              <div className="list-style">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
